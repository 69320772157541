import gql from 'graphql-tag';

export const GET_INITIAL_DATA = gql`
  query getInitialData {
    getLeadQualifier {
      id
      question
      type
      craftJsNodeId
      pageId
      choices {
        id
        title
        unit
        craftJsNodeId
      }
    }
    getFunnel {
      id
      customJs
      language
      enabled
      brandingEnabled
      cookieBanner
      connectedFonts {
        id
        type
        availableFont {
          id
          fontFamily
          fontLink
          type
        }
      }
    }
    getBookingSettings {
      bookingRangeInHours
    }
    getSubscriptionInfo {
      brandingEnabled
      customBrandingEnabled
      fileSizeLimitPerUploadInMB
      remainingVisits
    }
    getFunnelPages {
      name
      id
      craftState
      type
      pageOrder
      facebookPixelEvent
      tikTokPixelEvent
      googleTagTrigger
      linkedInEvent
      customJs
      redirectURL
    }
    getFunnelColorTheme {
      accentColor
      accentColorContrast
      backgroundColor
      cardBackgroundColor
      cardIconColor
      cardTextColor
      headlineColor
      fontFamily
      fontLink
      textColor
      defaultGradient
    }
    getThirdPartyTrackingSettings {
      provider
      providerId
      enabled
    }
    getGlobalSettingsFlags {
      key
      value
    }
    getUser {
      id
    }
    getFunnelPresets {
      id
      preset
      presetType
    }
  }
`;

export const SET_COOKIE_ACCEPTANCE = gql`
  mutation allowTracking($id: Int!, $allow: Boolean!) {
    allowTracking(id: $id, allow: $allow)
  }
`;

export const SEND_FBAPI_EVENT = gql`
  mutation sendFbApiEvent(
    $event: String!
    $url: String!
    $clientUserAgent: String!
    $clientIp: String!
    $testEventCode: String
    $eventID: String
    $fbclid: String
  ) {
    sendFbApiEvent(
      event: $event
      url: $url
      clientUserAgent: $clientUserAgent
      clientIp: $clientIp
      testEventCode: $testEventCode
      eventID: $eventID
      fbclid: $fbclid
    )
  }
`;

export const TRACK_VOICE_MESSAGE_PLAYBACK = gql`
  mutation trackVoiceMessagePlayback($stepKey: String!, $trackingId: Int!) {
    trackVoiceMessagePlayback(stepKey: $stepKey, trackingId: $trackingId)
  }
`;
