import React, { useState, useEffect } from 'react';
import IconProvider from '../../SharedUI/components/IconProvider';
import Popover from '../../SharedUI/components/Popover';
import { Form, Button, Modal } from 'react-bootstrap';
import { CookieBannerProps } from '../interfaces';
import {
  cookieMessageVisible,
  getAllowedCookies,
  setAllowedCookiesToLocalStorage,
} from '../helper';
import { getCookieBannerText } from '../config';
import { useAppDispatch, useAppSelector } from '../../redux/root/hooks';
import {
  selectIsCookieBannerOpen,
  setIsCookieBannerOpen,
  setLinkTreeOpenState,
} from '../../redux/slices/ui';
import { getThirdPartyTrackingSettingsByProvider } from '../../Pager/helper';
import { useMutation } from '@apollo/client';
import { SET_COOKIE_ACCEPTANCE } from '../../Pager/graphql';
import {
  initFacebookPixel,
  initGoogleTagManager,
  initLinkedInEvents,
  initMeetovoTracking,
  initProvenEmployerBadge,
  initTikTokPixel,
} from '../../Pager/utils/tracking';
import { useAuthenticatedSocket } from '../../helper/socket';
import { AVAILABLE_FONT_TYPE } from '../../redux/interfaces/mainData';

function CookieBanner({
  enabled,
  language,
  trackingId,
  thirdPartyTrackingSettings,
  facebookPixelEventAfterAcceptance,
  tiktokPixelEventAfterAcceptance,
  linkedInEventAfterAcceptance,
  googleTagTriggerAfterAcceptance,
  currentFunnelPageId,
  onClose,
  areVideosExisting,
  pageName,
  funnel,
}: CookieBannerProps): React.ReactElement | null {
  const allowedCookiesFromLocalStorage = getAllowedCookies();
  const thirdPartyTrackingSettingsByProvider = getThirdPartyTrackingSettingsByProvider(
    thirdPartyTrackingSettings,
  );

  const hasCustomFont = funnel.connectedFonts.every(
    (font: any) => font.availableFont.type === AVAILABLE_FONT_TYPE.CUSTOM_FONTS,
  );
  const {
    facebookPixel,
    facebookConversionApi,
    googleTagManager,
    tiktokPixel,
    linkedInEvent,
    provenEmployer,
  } = thirdPartyTrackingSettingsByProvider;
  const dispatch = useAppDispatch();
  const [consentManagerOpen, setConsentManagerOpen] = useState(false);
  const shouldShowCookieBanner =
    enabled &&
    (!localStorage.meetovoShowCookieBanner || cookieMessageVisible()) &&
    !allowedCookiesFromLocalStorage.length;
  const [showMessage, setShowMessage] = useState(shouldShowCookieBanner);
  const [showSubmissionPopup, setShowSubmissionPopup] = useState(false);
  const [activeCookieSettingId, setActiveCookieSettingId] = useState();
  const [checkedToggles, setCheckedToggles] = useState<any>(getAllowedCookies());
  const [setTrackingAcceptance] = useMutation(SET_COOKIE_ACCEPTANCE);
  const { socket, connected } = useAuthenticatedSocket();
  const isOpenFromUserClickInFooter = useAppSelector(selectIsCookieBannerOpen);
  localStorage.setItem('meetovoShowCookieBanner', shouldShowCookieBanner ? 'true' : 'false');

  useEffect(() => {
    if (isOpenFromUserClickInFooter !== null) {
      setShowMessage(isOpenFromUserClickInFooter);
    }
  }, [isOpenFromUserClickInFooter]);

  useEffect(() => {
    if (connected) initMeetovoTracking(socket, currentFunnelPageId);
  }, [connected]);

  useEffect(() => {
    if (!allowedCookiesFromLocalStorage.length) return;
    if (showMessage) handleHideMessage();

    handleTrackingInitalisation(checkedToggles);
  }, []);

  const handleHideMessage = (): void => {
    localStorage.setItem('meetovoShowCookieBanner', 'false');
    setShowMessage(false);
    dispatch(setIsCookieBannerOpen(false));
    setShowSubmissionPopup(false);
    setConsentManagerOpen(false);
  };

  const handleCheckedToggles = (id: string, checked: boolean): void => {
    if (checked) setCheckedToggles([...checkedToggles, id]);
    else {
      const newCheckedToggles = checkedToggles.reduce(
        (acc: string[], ct: string) => (ct !== id ? [...acc, ct] : acc),
        [],
      );

      setCheckedToggles(newCheckedToggles);
    }
  };

  const showTerms = (e: any): void => {
    dispatch(setLinkTreeOpenState(true));
  };

  const handleAcceptAll = (): void => {
    const allToggleIds = toggles.map(({ id }: any) => id);
    setCheckedToggles(allToggleIds);
    setAllowedCookiesToLocalStorage(allToggleIds);
    handleTrackingInitalisation(allToggleIds);
    handleHideMessage();
  };

  const handleRejectAll = (): void => {
    setCheckedToggles([]);
    setAllowedCookiesToLocalStorage([]);
    handleTrackingInitalisation([]);
    handleHideMessage();
  };

  const handleAcceptCheckedToggles = (): void => {
    if (checkedToggles.length < toggles.length && !showSubmissionPopup) {
      setShowSubmissionPopup(true);
      return;
    }

    setAllowedCookiesToLocalStorage(checkedToggles);
    handleTrackingInitalisation(checkedToggles);
    handleHideMessage();
  };

  const toggleConsentManagerOpen = (): void => {
    setConsentManagerOpen(!consentManagerOpen);
  };

  const handleTrackingInitalisation = (checkedToggles: string[]): void => {
    const thirdPartyTrackingSettingsByProvider = getThirdPartyTrackingSettingsByProvider(
      thirdPartyTrackingSettings,
    );

    initFacebookPixel(
      thirdPartyTrackingSettingsByProvider.facebookPixel,
      facebookPixelEventAfterAcceptance,
    );
    initTikTokPixel(
      thirdPartyTrackingSettingsByProvider.tiktokPixel,
      tiktokPixelEventAfterAcceptance,
      pageName,
    );
    initLinkedInEvents(
      thirdPartyTrackingSettingsByProvider.linkedInEvent,
      linkedInEventAfterAcceptance,
    );
    initGoogleTagManager(
      thirdPartyTrackingSettingsByProvider.googleTagManager,
      googleTagTriggerAfterAcceptance,
    );

    initProvenEmployerBadge(thirdPartyTrackingSettingsByProvider.provenEmployer);

    if (trackingId)
      setTrackingAcceptance({
        variables: { id: trackingId, allow: checkedToggles.includes('meetovo-tracking') },
      }).catch(console.error);

    onClose(checkedToggles);
  };

  const { banner, consentManager, confirmPopup } = getCookieBannerText({
    language,
    facebookPixelEnabled: facebookPixel.enabled,
    facebookConversionsApiEnabled: facebookConversionApi.enabled,
    googleTagManagerEnabled: googleTagManager.enabled,
    linkedInEventsEnabled: linkedInEvent.enabled,
    tiktokPixelEnabled: tiktokPixel.enabled,
    provenEmployerEnabled: provenEmployer.enabled,
    handleCheckedToggles,
    videosEnabled: areVideosExisting,
  });
  const { toggles, headline, explanation, acceptAll, acceptSelection } = consentManager;
  const {
    legalInformationText,
    acceptButtonText,
    cookieMessageText1,
    cookieMessageText2,
    openCookieSettingsText,
    rejectAllButtonText,
  } = banner;

  return showMessage ? (
    <>
      <div className="block-interaction__overlay"></div>
      <div className="cookie-banner">
        <div className="cookie-banner__inner">
          <img
            loading="lazy"
            className="cookie-banner__cookie"
            src={process.env.PUBLIC_URL + '/cookie.svg'}
          />

          <p className="cookie-banner__text">
            {cookieMessageText1}
            <span className="link-style" onClick={showTerms}>
              {legalInformationText}
            </span>
            . {cookieMessageText2}
          </p>

          <span onClick={handleAcceptAll} className="deeper-on-hover cookie-banner__submit">
            <IconProvider name="IoMdCheckmark" />
            {acceptButtonText}
          </span>

          <span onClick={toggleConsentManagerOpen} className="cookie-settings__open-settings">
            {openCookieSettingsText}
            <IconProvider name="IoIosArrowForward" />
          </span>

          <span className="cookie-settings__reject-all" onClick={handleRejectAll}>
            {rejectAllButtonText}
          </span>

          <Popover
            open={consentManagerOpen}
            onClose={toggleConsentManagerOpen}
            className="cookie-settings"
          >
            <header>
              <h4>{headline}</h4>
              <div dangerouslySetInnerHTML={{ __html: explanation }}></div>
            </header>

            <Modal centered show={showSubmissionPopup} onHide={() => {}}>
              <Modal.Body>
                <p>{confirmPopup.text}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="submissionpopup__accept-checked"
                  variant="link"
                  onClick={handleAcceptCheckedToggles}
                >
                  {confirmPopup.acceptSelection}
                </Button>
                <Button
                  className="submissionpopup__accept-all"
                  variant="success"
                  onClick={handleAcceptAll}
                >
                  {confirmPopup.acceptAll}
                </Button>
              </Modal.Footer>
            </Modal>

            <Form>
              {toggles.map(({ title, explanation, callback, id }: any, i: number) => {
                return funnel.connectedFonts.length !== 0 &&
                  hasCustomFont &&
                  title === 'Google Fonts bearbeiten' ? null : (
                  <React.Fragment key={id}>
                    {activeCookieSettingId === id ? (
                      <Form.Check
                        onChange={(e: any) => callback(id, e.target.checked)}
                        type="switch"
                        id={`cookie-switch-${i}`}
                        label={title}
                        checked={checkedToggles.includes(id)}
                      />
                    ) : (
                      <a onClick={() => setActiveCookieSettingId(id)}>{title}</a>
                    )}
                    <p>{explanation}</p>
                  </React.Fragment>
                );
              })}
            </Form>

            <div className="cookie-settings__buttons">
              <Button
                onClick={handleAcceptAll}
                variant="success"
                className="display-block-important w-100"
              >
                {acceptAll}
              </Button>
              <Button
                onClick={handleAcceptCheckedToggles}
                variant="link"
                className="display-block-important w-100"
              >
                {acceptSelection}
              </Button>
            </div>
          </Popover>
        </div>
      </div>
    </>
  ) : null;
}

export default CookieBanner;
